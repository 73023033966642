<script setup>
 const loading = ref(true);
 const loggedIn = ref(false);
 const link = ref('https://cart.architecture.org/account/login');
 onMounted(async () => {
  const isLoggedIn = await $fetch(`/api/tn/auth`);
  loggedIn.value = isLoggedIn.loggedIn;

  link.value = link.value + '?returnUrl=' + window.location.href;

  if (loggedIn.value) {
   link.value = 'https://cart.architecture.org/account/logout' + '?returnUrl=' + window.location.href;
  }

  loading.value = false;
 });
</script>
<template>
 <Transition name="fade" mode="out-in">
  <MessLoading v-if="loading" class="login-logout-loading" />
  <ProjectButton v-else class="login-logout" :link="link">
   <span class="label"> {{ loggedIn ? 'Logout' : 'Login' }} </span>
   <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" fill="none">
    <path
     d="M7.36944 8.68189C9.62885 8.68189 11.4605 6.85028 11.4605 4.59087C11.4605 2.33146 9.62885 0.499847 7.36944 0.499847C5.11003 0.499847 3.27841 2.33146 3.27841 4.59087C3.27841 6.85028 5.11003 8.68189 7.36944 8.68189Z"
     fill="currentColor"
    />
    <path
     d="M0.694308 18.5002H14.1138C14.4944 18.5002 14.803 18.1916 14.803 17.811V13.0692C14.803 11.5263 13.5532 10.2714 12.0052 10.2714H2.79779C1.25489 10.2714 0 11.5211 0 13.0692V17.811C0.005143 18.1916 0.313726 18.5002 0.694308 18.5002Z"
     fill="currentColor"
    />
   </svg>
  </ProjectButton>
 </Transition>
</template>

<style>
 .login-logout-loading {
  margin-left: 0;
  margin-top: 2rem;
 }
</style>
